import React from 'react';
import Mailchimp from './react-mailchimp-form2';

export default function Mail() {
  return (
    <Mailchimp
      action='https://robotstack.us2.list-manage.com/subscribe/post?u=4acf1487de9db34599c829816&id=eedf9b775a'
      fields={[
        {
          name: 'EMAIL',
          placeholder: 'Email',
          type: 'email',
          required: true
        }
      ]}
      // Change predetermined language
      messages = {
        {
          sending: "Sending...",
          success: "Thank you for subscribing!",
          error: "An unexpected internal error has occurred.",
          empty: "You must write an e-mail.",
          duplicate: "Too many subscribe attempts for this email address",
          button: "Sign Up!",
        }
      }
      // Add a personalized class
      className='mailForm'
      buttonClassName = 'mailButton'
    />
  )
};

