import React from 'react';
import { Animate, AnimateKeyframes, AnimateGroup } from 'react-simple-animate';
import images from './Images';
import PropTypes from 'prop-types';

class Animation extends React.Component {

  componentDidMount() {
    images.forEach(picture => {
      let img = new Image();
      img.src = picture.fileName;
      console.log(img.src);
    });
  }

  render() {
  const {play} = this.props;
    return (
      <div className="explodedViewContainer">
        <img src={images[0].src} className="imageStack" rel="preload" />
        <AnimateGroup play={play}>
          {images.map(({ src, id }) => {
            return (
              <Animate
                key={src}
                sequenceIndex={id}
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
                duration={0.03}
              >
                <img src={src} className="imageStack" rel="preload" />
              </Animate>
            );
          })}
        </AnimateGroup>
      </div>
    );
  }
}

Animation.propTypes = {
  play: PropTypes.bool,
}

export default Animation;
