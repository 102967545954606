import mo00 from '../assets/images/png/mo00.png';
import mo01 from '../assets/images/png/mo01.png';
import mo02 from '../assets/images/png/mo02.png';
import mo03 from '../assets/images/png/mo03.png';
import mo04 from '../assets/images/png/mo04.png';
import mo05 from '../assets/images/png/mo05.png';
import mo06 from '../assets/images/png/mo06.png';
import mo07 from '../assets/images/png/mo07.png';
import mo08 from '../assets/images/png/mo08.png';
import mo09 from '../assets/images/png/mo09.png';
import mo10 from '../assets/images/png/mo10.png';
import mo11 from '../assets/images/png/mo11.png';
import mo12 from '../assets/images/png/mo12.png';
import mo13 from '../assets/images/png/mo13.png';
import mo14 from '../assets/images/png/mo14.png';
import mo15 from '../assets/images/png/mo15.png';
import mo16 from '../assets/images/png/mo16.png';
import mo17 from '../assets/images/png/mo17.png';
import mo18 from '../assets/images/png/mo18.png';
import mo19 from '../assets/images/png/mo19.png';
import mo20 from '../assets/images/png/mo20.png';
import mo21 from '../assets/images/png/mo21.png';
import mo22 from '../assets/images/png/mo22.png';
import mo23 from '../assets/images/png/mo23.png';
import mo24 from '../assets/images/png/mo24.png';
import mo25 from '../assets/images/png/mo25.png';
import mo26 from '../assets/images/png/mo26.png';
import mo27 from '../assets/images/png/mo27.png';
import mo28 from '../assets/images/png/mo28.png';
import mo29 from '../assets/images/png/mo29.png';
import mo30 from '../assets/images/png/mo30.png';
import mo31 from '../assets/images/png/mo31.png';
import mo32 from '../assets/images/png/mo32.png';
import mo33 from '../assets/images/png/mo33.png';
import mo34 from '../assets/images/png/mo34.png';
import mo35 from '../assets/images/png/mo35.png';
import mo36 from '../assets/images/png/mo36.png';
import mo37 from '../assets/images/png/mo37.png';
import mo38 from '../assets/images/png/mo38.png';
import mo39 from '../assets/images/png/mo39.png';
import mo40 from '../assets/images/png/mo40.png';
import mo41 from '../assets/images/png/mo41.png';
import mo42 from '../assets/images/png/mo42.png';
import mo43 from '../assets/images/png/mo43.png';
import mo44 from '../assets/images/png/mo44.png';
import mo45 from '../assets/images/png/mo45.png';
import mo46 from '../assets/images/png/mo46.png';
import mo47 from '../assets/images/png/mo47.png';


// images.js
const images = [
    { id: 0, src: mo00 },
    { id: 1, src: mo01 },
    { id: 2, src: mo02 },
    { id: 3, src: mo03 },
    { id: 4, src: mo04 },
    { id: 5, src: mo05 },
    { id: 6, src: mo06 },
    { id: 7, src: mo07 },
    { id: 8, src: mo08 },
    { id: 9, src: mo09 },
    { id: 10, src: mo10 },
    { id: 11, src: mo11 },
    { id: 12, src: mo12 },
    { id: 13, src: mo13 },
    { id: 14, src: mo14 },
    { id: 15, src: mo15 },
    { id: 16, src: mo16 },
    { id: 17, src: mo17 },
    { id: 18, src: mo18 },
    { id: 19, src: mo19 },
    { id: 20, src: mo20 },
    { id: 21, src: mo21 },
    { id: 22, src: mo22 },
    { id: 23, src: mo23 },
    { id: 24, src: mo24 },
    { id: 25, src: mo25 },
    { id: 26, src: mo26 },
    { id: 27, src: mo27 },
    { id: 28, src: mo28 },
    { id: 29, src: mo29 },
    { id: 30, src: mo30 },
    { id: 31, src: mo31 },
    { id: 32, src: mo32 },
    { id: 33, src: mo33 },
    { id: 34, src: mo34 },
    { id: 35, src: mo35 },
    { id: 36, src: mo36 },
    { id: 37, src: mo37 },
    { id: 38, src: mo38 },
    { id: 39, src: mo39 },
    { id: 40, src: mo40 },
    { id: 41, src: mo41 },
    { id: 42, src: mo42 },
    { id: 43, src: mo43 },
    { id: 44, src: mo44 },
    { id: 45, src: mo45 },
    { id: 46, src: mo46 },
    { id: 47, src: mo47 }
  ];
  
  export default images;
