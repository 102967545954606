import React from 'react';
import InViewMonitor from 'react-inview-monitor';
import Animation from './Animation';

const buttonStyle = {
  position: 'absolute',
  top: -300,
  backgroundColor: 'pink',
  zIndex: 1,
};

class ExplodedView extends React.Component {
  state = {
    play: false,
  };

  render() {
    const { play } = this.state;
    return (
      <div>
        {/* <button
          style={buttonStyle}
          onClick={() => this.setState(({ play }) => ({ play: !play }))}
        >
          Play
        </button> */}
        <InViewMonitor
          childPropsInView={{ play: true }}
          toggleChildPropsOnInView={true}
          intoViewMargin="0px"
        >
          <Animation play={play} />
        </InViewMonitor>
      </div>
    );
  }
}

export default ExplodedView;
