import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Scroll from '../components/Scroll';
import ExplodedView from '../components/ExplodedView';
import Mail from '../components/Mail';
import demo1 from '../assets/images/moti-frontal.png';

const IndexPage = () => (
  <Layout>
    <Header />
    <header className="masthead" id="page-top">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h2 className="mb-5">
                <span className="underline">Moti</span> smart motors make it
                easier to build robots
                <br />
                <span className="shrink">
                  Use the app for instant control.
                  <br />
                  Customize with code and
                  <br />
                  advanced features.
                </span>
              </h2>
              <Scroll type="id" element="cta">
                <a href="#cta" className="btn btn-outline btn-xl">
                  Sign Up
                </a>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device">
                <img src={demo1} className="img-fluid" alt="" />
                <div className="button"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="preview download bg-primary text-center" id="preview">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">Preview</h2>
            <hr />
            <br></br>
            <div
              className="video"
              style={{
                position: 'relative',
                paddingBottom: '56.25%' /* 16:9 */,
                paddingTop: 25,
                height: 0,
              }}
            >
              <iframe
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
                src={
                  'https://fast.wistia.net/embed/iframe/xtwjvviq0a?videoFoam=true.com/video/477297135'
                }
                frameBorder="0"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Features</h2>
          {/* <p className="text-muted">
            Check out what you can do with this app theme!
          </p> */}
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              {/* <div className="device-mockup iphone6_plus portrait white"> */}
              <div className="device">
                <div className="screen">
                  {/* <img src={demo1} className="img-fluid" alt="" /> */}
                  <ExplodedView />
                  {/* <img src={moGif} className="gif" /> */}
                </div>
                <div className="button"></div>
              </div>
              {/* </div> */}
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Ready-Made App</h3>
                    <p className="text-muted">
                      Use dials, timelines and other graphical components to get
                      moving quickly.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Programmable</h3>
                    <p className="text-muted">
                      Moti is Arduino Compatible and has a RESTful API.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>On-board Sensors</h3>
                    <p className="text-muted">
                      Includes a 12 bit Magnetic Encoder, Current, Voltage and
                      Thermal Sensing.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Networking</h3>
                    <p className="text-muted">
                      Connect up to 32 Motis together on a RS485 bus and control all
                      over USB.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary"></i>
                    <h3>Extensible</h3>
                    <p className="text-muted">
                      Use the breakout pins to add bluetooth, wifi, GPS, IMUs,
                      or other peripherals as needed.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary"></i>
                    <h3>Open Source</h3>
                    <p className="text-muted">
                      With an LGPL license, you can use it commercially.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="cta" id="cta">
      <div className="cta-content">
        <div className="container">
          <h2>Ready for launch?</h2>
          <h5>Subscribe to mailing list</h5>
          <Mail className="mail" />
          <h6 className="about">
            Moti is made by RobotStack
            <br />
            in Toronto Canada
            <br />
            <br />
            <a href="mailto: info@robotstack.com" className="mailto">info@robotstack.com</a>
          </h6>
          <Scroll type="id" element="contact"></Scroll>
        </div>
      </div>
      <div className="overlay"></div>
    </section>

    {/* <section className="contact" id="contact">
      <div className="container">
        <p>
          RobotStack is based in Toronto, Canada. 
        </p>
        <Contact />
      </div>
    </section> */}
  </Layout>
);

export default IndexPage;
